import { DetailedHTMLProps, LinkHTMLAttributes, memo, useMemo } from "react";
import Head from "next/head";

import generateImgixUrl from "utils/image/generateImgixUrl";
import getImageSourceUrl from "utils/image/getImageSourceUrl";
import imageScreenSizes from "utils/image/imageScreenSizes";

import { ImageProps, Size } from "./Image";

type Props = Pick<ImageProps, "mobileSrc" | "sizes" | "src" | "tabletSrc"> & {
  params?: Record<string, string>;
};

const getNextNonNullIndex = (startIndex: number, sizes: Size[]): number => {
  const index = sizes.slice(startIndex).findIndex((size) => size !== null);
  return index > -1 ? index + startIndex : index;
};

const generateMediaQuery = (index: number, sizes: Size[]) => {
  let query = "";
  if (sizes[index] !== null) {
    const nextIndex = getNextNonNullIndex(index + 1, sizes);
    if (sizes[index - 1] || (sizes.length > 1 && nextIndex === -1)) {
      query = `(min-width: ${imageScreenSizes[index]}px) and `;
    }

    if (nextIndex > -1) {
      query = `${query}(max-width: ${imageScreenSizes[nextIndex] - 1}px) and `;
    }
  }

  return query;
};

export default memo(({ mobileSrc, params, sizes, src, tabletSrc }: Props) => {
  const linkProps = useMemo(() => {
    const props: DetailedHTMLProps<
      LinkHTMLAttributes<HTMLLinkElement>,
      HTMLLinkElement
    >[] = [];

    const sizesSlice = sizes.slice(0, 5);

    sizesSlice.forEach((size: number | null, index: number) => {
      if (size) {
        const url = getImageSourceUrl(index, src, mobileSrc, tabletSrc);
        const href = generateImgixUrl(url, { ...params, w: `${size}` });
        const lowResImage = `${href}&dpr=1`;
        const highResImage = `${href}&dpr=2`;
        const mediaQuery = generateMediaQuery(index, sizesSlice);

        props.push({
          href: lowResImage,
          key: lowResImage,
          media: `${mediaQuery}(max-resolution: 1.99x), ${mediaQuery}(-webkit-max-device-pixel-ratio: 1.99)`,
        });
        props.push({
          href: highResImage,
          key: highResImage,
          media: `${mediaQuery}(min-resolution: 2x), ${mediaQuery}(-webkit-min-device-pixel-ratio: 2)`,
        });
      }
    });

    return props;
  }, [mobileSrc, `${params}`, `${sizes}`, src, tabletSrc]);

  return (
    <Head>
      {linkProps.map((size) => (
        // eslint-disable-next-line react/jsx-key -- key is on the size prop
        <link as="image" rel="preload" {...size} />
      ))}
    </Head>
  );
});
